import { graphql, useStaticQuery } from 'gatsby';
import { EligibleCountries, DirectusEligibleCountries } from './eligible-countries.interface';

export default (): EligibleCountries => {
  const { directusEligibleCountry } = useStaticQuery<DirectusEligibleCountries>(graphql`
    query {
      directusEligibleCountry {
        last_updated
        content
      }
    }
  `);

  return directusEligibleCountry;
};
