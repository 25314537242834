export const AUTH_COOKIE_KEY = 'ef_static_rf';
export const AUTH_COOKIE_VALUE = 'true';

export const isAuthenticated = (documentCookie: string): boolean => {
  try {
    const cookies = documentCookie.split(';');
    const found = cookies.find((cookie) => {
      const [key, value] = cookie.split('=');

      return key.trim() === AUTH_COOKIE_KEY && value.trim() === AUTH_COOKIE_VALUE;
    });

    return found !== undefined;
  } catch (e) {
    return false;
  }
};
