import React from 'react';

import { privacyPolicyTable } from './PrivacyPolicyTable.scss';

const PrivacyPolicyTable = (): JSX.Element => (
  <div className={privacyPolicyTable}>
    <table>
      <thead>
        <tr>
          <th>Why does RF need to use the personal data?</th>
          <th>What personal data is required?</th>
          <th>What is RF&apos;s lawful basis for processing?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ul>
              <li>to register you as a new customer</li>
              <li>to carry out RF&apos;s mail forwarding services</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To deliver a contractual service to you</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>to process your order </li>
              <li>to deliver your products and services</li>
              <li>to manage payments, fees and charges</li>
              <li>for debt recovery purposes</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Financial Data</li>
              <li>Transaction Data</li>
              <li>Marketing and Communication Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To deliver a contractual service to you</li>
              <li>To pursue RF&apos;s legitimate interest in recovering any debts due to it</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>to manage customer relationships</li>
              <li>to request reviews from customers </li>
              <li>to keep customers informed about changes to any of RF&apos;s terms or policies</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Profile Data</li>
              <li>Marketing and Communication Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To deliver a contractual service to you</li>
              <li>
                To pursue RF&apos;s legitimate interest in communicating with customers, maintaining accurate records
                and conducting analysis on how customers use its products and services
              </li>
              <li>To comply with a legal obligation</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>
                to administer and protect our business and this website (including troubleshooting, data analysis,
                testing, system maintenance, support, reporting and hosting of data)
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To comply with a legal obligation</li>
              <li>
                To pursue RF&apos;s legitimate interest in operating the business, provision of administration and IT
                services, network security, to prevent fraud and in the context of a business reorganisation or group
                restructuring exercise
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>to deliver relevant website content/advertisements to you</li>
              <li>to measure and understand how effective RF&apos;s advertising is</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
              <li>Usage Data</li>
              <li>Profile Data</li>
              <li>Marketing and Communications Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>
                To pursue RF&apos;s legitimate interest in analysing and understanding how customers use its products
                and services, to develop products and services, to inform RF&apos;s marketing strategy and grow the
                business
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>to use data analytics to improve RF&apos;s website, products and services</li>
              <li>to inform RF&apos;s marketing strategies </li>
              <li>to understand and improve RF&apos;s customer relationships and experiences</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Technical Data</li>
              <li>Usage Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>
                To pursue RF&apos;s legitimate interest in identifying the types of customers for its products and
                services, to keep the website updated and relevant and to develop business and marketing efforts
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>
                to suggest and recommend any of RF&apos;s goods or services that may be of interest, including
                promotional offers
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
              <li>Usage Data</li>
              <li>Profile Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>
                To pursue RF&apos;s legitimate interest in developing products and services and expanding the business
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>
                to share with RF&apos;s referral partners where you have requested this, including requesting a service
                at checkout or clicking RF&apos;s referral partners&apos; links
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
              <li>Usage Data</li>
              <li>Profile Data</li>
              <li>Marketing and Communications Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To deliver a contractual service to you</li>
              <li>Your consent</li>
              <li>
                To pursue RF&apos;s legitimate interest in developing products and services and expanding the business
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>To enable you to take part in prize draws and competitions</li>
              <li>To enable you to complete surveys</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
              <li>Profile Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To deliver a contractual service to you</li>
              <li>
                To pursue RF&apos;s legitimate interest in developing products and services and expanding the business
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>
                to carry out identity verification checks and ongoing monitoring in relation to our customers and
                associated individuals
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Identity Data</li>
              <li>Content Data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To deliver a contractual service to you</li>
              <li>To comply with a legal obligation</li>
              <li>To pursue RF&apos;s legitimate interest in the prevention of crime and record keeping</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default PrivacyPolicyTable;
