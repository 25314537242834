import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';

import { Props } from './DropDownList.interface';

import {
  dropDown,
  dropDown___default,
  dropDown__button,
  dropDown__button___default,
  dropDown__button___footerStyle,
  dropDown__title,
  dropDown__toggle,
  dropDown__toggle__icon,
  list,
  list__open,
  list__closed,
} from './DropDownList.scss';

export const DropDownList = ({ children, title, type }: Props): JSX.Element => {
  const [isToggled, updateIsToggled] = useState<boolean>(false);
  const toggleDropdown = (): void => updateIsToggled(!isToggled);

  const rootStyle = type === 'footer' ? dropDown : `${dropDown} ${dropDown___default}`;

  const buttonStyle =
    type === 'footer'
      ? `${dropDown__button} ${dropDown__button___footerStyle}`
      : `${dropDown__button} ${dropDown__button___default}`;

  return (
    <div className={rootStyle}>
      <div onClick={toggleDropdown} className={buttonStyle} role="presentation">
        <h5 className={dropDown__title}>{title}</h5>

        <div className={dropDown__toggle}>
          <span className={dropDown__toggle__icon}>
            <FontAwesomeIcon icon={isToggled ? faMinus : faPlus} title="toggle drop-down list" />
          </span>
        </div>
      </div>

      <ul className={`${list} ${isToggled ? list__open : list__closed}`}>{children}</ul>
    </div>
  );
};
