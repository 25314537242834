import React from 'react';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import BannerHook from 'hooks/special-offer-banner/special-offer-banner.hook';
import { Props } from './Banner.interface';

import {
  banner as bannerClass,
  banner__wrapper,
  banner__body,
  banner__btnPrimary,
  banner__colorWhite,
  banner__colorBlack,
  banner__colorBlue,
  banner__colorGreen,
} from './Banner.scss';

const bannerColor = (color: string): string => {
  switch (color) {
    case 'white':
      return banner__colorWhite;
    case 'black':
      return banner__colorBlack;
    case 'blue':
      return banner__colorBlue;
    case 'green':
      return banner__colorGreen;
    default:
      return '';
  }
};

export default ({ children, color, className }: Props): JSX.Element => {
  const banner = BannerHook();

  return (
    <div key="banner" className={`${bannerClass} ${bannerColor(color)} ${className || ''}`}>
      <div className={banner__wrapper}>
        <div className={banner__body}>
          <em>
            <Markdown source={banner.heading} />
          </em>
          <span>
            <Markdown source={banner.subheading} />
          </span>
          <Button size="small" role="success" className={banner__btnPrimary} to={banner.button_link}>
            {banner.button_text}
          </Button>
          {children}
        </div>
      </div>
    </div>
  );
};
