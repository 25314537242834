import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPartner, Partners } from './partners.interface';

export default (): Partners[] => {
  const { allDirectusPartner } = useStaticQuery<AllDirectusPartner>(graphql`
    query {
      allDirectusPartner(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            slug
            name
            logo {
              data {
                full_url
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusPartner.edges;
};
