// extracted by mini-css-extract-plugin
export var btn = "Button--btn--Ntav2";
export var btnBlock = "Button--btnBlock--6tIn-";
export var btnDanger = "Button--btnDanger--vBIku";
export var btnDisabled = "Button--btn--disabled--e8onY";
export var btnLarge = "Button--btnLarge--4ww+W";
export var btnPrimary = "Button--btnPrimary--1vDxx";
export var btnSecondary = "Button--btnSecondary--5STdF";
export var btnSmall = "Button--btnSmall--QCx8z";
export var btnSuccess = "Button--btnSuccess--mFXdJ";
export var btnText = "Button--btnText--aXOhM";
export var btnWarning = "Button--btnWarning--Srvbr";
export var tkMyriadProSemiCondensed = "Button--tk-myriad-pro-semi-condensed--KNl6U";