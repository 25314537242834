import React from 'react';

import Navigation from 'components/directus/navigation/Navigation';
import { Footer } from 'components/directus/footer/Footer';
import { Props } from '../Layouts.interface';

import { app as appClass } from '../Layouts.scss';

export default ({ children }: Props): JSX.Element => (
  <div className={appClass}>
    <div>
      <Navigation isHome={false} />
      {children}
      <Footer />
    </div>
  </div>
);
