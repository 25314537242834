import { ContactUsRequest, ContactUsResponse } from './contact-us.interface';

export const submitContactUs = async (body: ContactUsRequest): Promise<ContactUsResponse> => {
  const url = process.env.GATSBY_CONTACT_US_FORM_URL;

  try {
    if (url === undefined) {
      throw new Error('ENV "GATSBY_EFILING_CONTACT_US_FORM_URL" is undefined.');
    }

    const res = await fetch(url, {
      body: JSON.stringify({
        name: body.name,
        email: body.email,
        question: body.question,
        department: body.department,
      }),
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      referrer: 'no-referrer',
    });

    if (res.status >= 400) {
      throw new Error('There was an error processing your request. Please try again later.');
    }

    return {
      status: 'success',
    };
  } catch (ex) {
    return {
      status: 'error',
      error: 'Something has went wrong. Please try again later.',
    };
  }
};
