import React from 'react';

import partnersHook from 'hooks/partners/partners.hook';

import { Partner } from './Partners.interface';

import { partners as partnersClass, partners__partner, partners__logo } from './Partners.scss';

export default (): JSX.Element => {
  const partners = partnersHook();

  return (
    <div className={partnersClass}>
      {partners.map(({ node }: Partner) => (
        <div key={`partner-${node.slug}`} className={partners__partner}>
          <img className={partners__logo} src={node.logo.data.full_url} alt={`${node.name} partner logo.`} />
        </div>
      ))}
    </div>
  );
};
