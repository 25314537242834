import React from 'react';

import Accreditation from 'components/directus/accreditation/Accreditation';

import { accreditations, accreditations__accreditation } from './Accreditations.scss';

export default (): JSX.Element => (
  <div className={accreditations}>
    <Accreditation slug="cdb" className={accreditations__accreditation} width={75} />
    <Accreditation slug="green-mark" className={accreditations__accreditation} width={85} />
    <Accreditation slug="acra" className={accreditations__accreditation} width={115} />
    <Accreditation slug="living-wage-employer" className={accreditations__accreditation} width={85} />
    <Accreditation slug="mindful-employer" className={accreditations__accreditation} width={115} />
    <Accreditation slug="cse" className={accreditations__accreditation} width={85} />
  </div>
);
