import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';

import socialIconHook from 'hooks/social-icon/social-icon.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './SocialIcon.interface';

export const SocialIcon = ({ slug, className }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, socialIconHook());
  const iconLookup = (slug: string): any => {
    switch (slug) {
      case 'facebook':
        return faFacebookSquare;
      case 'twitter':
        return faSquareXTwitter;
      case 'linkedin':
        return faLinkedin;
      case 'instagram':
        return faInstagramSquare;
      default:
        throw new Error(`No icon exists for the slug: ${slug}`);
    }
  };

  return (
    <a
      href={node.url}
      className={className}
      title={`Social icon for ${slug}`}
      aria-label={`Social icon for ${slug}`}
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={iconLookup(node.slug)} color={node.icon_color} size="2x" />
    </a>
  );
};
