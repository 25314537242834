import { graphql, useStaticQuery } from 'gatsby';
import { DirectusSpecialOffer, Offer } from './special-offer-banner.interface';

export default (): Offer => {
  const { directusSpecialOffer } = useStaticQuery<DirectusSpecialOffer>(graphql`
    query {
      directusSpecialOffer {
        heading
        subheading
        button_text
        button_link
      }
    }
  `);

  return directusSpecialOffer;
};
