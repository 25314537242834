import React from 'react';

import { Wrapper } from 'components/common/wrapper/Wrapper';
import { DropDownList, ListItem } from 'components/directus/drop-down-list';
import Accreditations from './accreditations/Accreditations';
import Company from './company/Company';
import Contacts from './contacts/Contacts';
import CardIcons from './card-icons/CardIcons';
import SocialIcons from './social-icons/SocialIcons';

import {
  footer,
  footer__icons,
  footer__social,
  footer__links,
  footer__company,
  footer__company__divider,
  footer__company__accreditation,
} from './Footer.scss';

export const Footer = (): JSX.Element => (
  <footer className={footer} itemType="http://schema.org/Organization">
    <Wrapper>
      <div className={footer__icons}>
        <CardIcons />

        <div className={footer__social}>
          <SocialIcons slugs={['instagram', 'facebook', 'twitter', 'linkedin']} />
        </div>
      </div>

      <div className={footer__links}>
        <DropDownList title="Formation Packages" type="footer">
          <ListItem to="/compare-packages/">Compare Packages</ListItem>
          <ListItem to="/package/basic-package/">Basic Package</ListItem>
          <ListItem to="/package/privacy-package/">Privacy Package</ListItem>
          <ListItem to="/package/privacy-plus-package/">Privacy Plus Package</ListItem>
          <ListItem to="/package/all-inclusive-package/">All Inclusive Package</ListItem>
          <ListItem to="/compare-packages/non-residents/">Non-Residents Packages</ListItem>
          <ListItem to="/compare-packages/scottish/">Scottish Package</ListItem>
          <ListItem to="/compare-packages/guarantee/">Guarantee Package</ListItem>
          <ListItem to="/compare-packages/llp/">LLP Package</ListItem>
        </DropDownList>

        <DropDownList title="Address Services" type="footer">
          <ListItem to="/additional-services/london-registered-office/">London Registered Office</ListItem>
          <ListItem to="/additional-services/scottish-registered-office/">Scottish Registered Office</ListItem>
          <ListItem to="/additional-services/service-address/">Service Address</ListItem>
          <ListItem to="/additional-services/business-address/">Business Address</ListItem>
          <ListItem to="/company-address-guide/">Company Address Guide</ListItem>
          <ListItem to="/renewals/">Renewals</ListItem>
        </DropDownList>

        <DropDownList title="Corporate Services" type="footer">
          <ListItem to="/confirmation-statement-service/">Confirmation Statement</ListItem>
          <ListItem to="/full-company-secretary/">Full Company Secretary Service</ListItem>
          <ListItem to="/dormant-company-accounts/">Dormant Company Accounts</ListItem>
          <ListItem to="/apostilled-documents/">Apostilled Documents</ListItem>
          <ListItem to="/certificate-of-good-standing/">Certificate of Good Standing</ListItem>
          <ListItem to="/company-dissolution/">Company Dissolution</ListItem>
          <ListItem to="/company-name-change/">Company Name Change</ListItem>
          <ListItem to="/director-appointment-resignation/">Director Appointment &amp; Resignation</ListItem>
          <ListItem to="/bizik-answering-services/">Bizik Answering Services</ListItem>
          <ListItem to="/issue-of-shares/">Issue of Shares</ListItem>
          <ListItem to="/transfer-of-shares/">Transfer of Shares</ListItem>
          <ListItem to="/vat-registration/">VAT Registration</ListItem>
        </DropDownList>

        <DropDownList title="Support" type="footer">
          <ListItem to="/contact-us/">Contact Us</ListItem>
          <ListItem to="/affiliate-program/">Affiliate Program</ListItem>
          <ListItem to="/help-centre/online-admin-portal/">Online Client Portal</ListItem>
          <ListItem to="/id-requirements/">ID Requirements</ListItem>
          <ListItem to="/blog/">Blog</ListItem>
          <ListItem to="/faqs/">FAQs</ListItem>
          <ListItem to="/help-centre/a-z-glossary-of-terms/">A-Z Glossary of Terms</ListItem>
          <ListItem to="/entrepreneur-scholarship-programme/">Scholarship Programme</ListItem>
          <ListItem to="/customer-reviews/">Review Centre</ListItem>
          <ListItem to="/site-map/">Sitemap</ListItem>
        </DropDownList>

        <DropDownList title="Legal" type="footer">
          <ListItem to="/terms-and-conditions/">Terms &amp; Conditions</ListItem>
          <ListItem to="/refund-cancellation-policy/">Refund &amp; Cancellation Policy</ListItem>
          <ListItem to="/terms-and-conditions/complaints-procedure/">Complaints</ListItem>
          <ListItem to="/privacy-policy/">Privacy Policy</ListItem>
          <ListItem to="/cookies-policy/">Cookies Policy</ListItem>
        </DropDownList>
      </div>

      <div className={footer__company}>
        <Company />
        <div aria-hidden="true" className={footer__company__divider} />
        <div className={footer__company__accreditation}>
          <Accreditations />
        </div>
      </div>

      <Contacts />
    </Wrapper>
  </footer>
);
