import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { faBoxFull } from '@fortawesome/pro-solid-svg-icons/faBoxFull';
import { faCalendarTimes } from '@fortawesome/pro-solid-svg-icons/faCalendarTimes';
import { faCartArrowDown } from '@fortawesome/pro-solid-svg-icons/faCartArrowDown';
import { faCashRegister } from '@fortawesome/pro-solid-svg-icons/faCashRegister';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faClipboardListCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardListCheck';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop';
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons/faDotCircle';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faFileArchive } from '@fortawesome/pro-solid-svg-icons/faFileArchive';
import { faFileChartLine } from '@fortawesome/pro-solid-svg-icons/faFileChartLine';
import { faFileEdit } from '@fortawesome/pro-solid-svg-icons/faFileEdit';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faGlobeAmericas } from '@fortawesome/pro-solid-svg-icons/faGlobeAmericas';
import { faGlobeEurope } from '@fortawesome/pro-solid-svg-icons/faGlobeEurope';
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faLifeRing } from '@fortawesome/pro-solid-svg-icons/faLifeRing';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faMoneyCheckEdit } from '@fortawesome/pro-solid-svg-icons/faMoneyCheckEdit';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt';
import { faPhoneSquareAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneSquareAlt';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faShieldAlt } from '@fortawesome/pro-solid-svg-icons/faShieldAlt';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faTools } from '@fortawesome/pro-solid-svg-icons/faTools';
import { faUniversity } from '@fortawesome/pro-solid-svg-icons/faUniversity';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faWindowAlt } from '@fortawesome/pro-solid-svg-icons/faWindowAlt';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export const getSolidFontAwesomeIcon = (name: string): IconDefinition => {
  switch (name) {
    case 'faAngleRight':
      return faAngleRight;
    case 'faBadgeCheck':
      return faBadgeCheck;
    case 'faBoxFull':
      return faBoxFull;
    case 'faCalendarTimes':
      return faCalendarTimes;
    case 'faCartArrowDown':
      return faCartArrowDown;
    case 'faCashRegister':
      return faCashRegister;
    case 'faCheck':
      return faCheck;
    case 'faChevronRight':
      return faChevronRight;
    case 'faClipboardListCheck':
      return faClipboardListCheck;
    case 'faClock':
      return faClock;
    case 'faComments':
      return faComments;
    case 'faDesktop':
      return faDesktop;
    case 'faDotCircle':
      return faDotCircle;
    case 'faEnvelope':
      return faEnvelope;
    case 'faFlag':
      return faFlag;
    case 'faFile':
      return faFile;
    case 'faFileAlt':
      return faFileAlt;
    case 'faFileArchive':
      return faFileArchive;
    case 'faFileChartLine':
      return faFileChartLine;
    case 'faFileEdit':
      return faFileEdit;
    case 'faFileExcel':
      return faFileExcel;
    case 'faGift':
      return faGift;
    case 'faGlobeAmericas':
      return faGlobeAmericas;
    case 'faGlobeEurope':
      return faGlobeEurope;
    case 'faGraduationCap':
      return faGraduationCap;
    case 'faHeart':
      return faHeart;
    case 'faInfoCircle':
      return faInfoCircle;
    case 'faLifeRing':
      return faLifeRing;
    case 'faLock':
      return faLock;
    case 'faMapMarker':
      return faMapMarker;
    case 'faMapMarkerAlt':
      return faMapMarkerAlt;
    case 'faMoneyCheckEdit':
      return faMoneyCheckEdit;
    case 'faPhone':
      return faPhone;
    case 'faPhoneAlt':
      return faPhoneAlt;
    case 'faPhoneSquareAlt':
      return faPhoneSquareAlt;
    case 'faPhoneRotary':
      return faPhoneRotary;
    case 'faPlusCircle':
      return faPlusCircle;
    case 'faPrint':
      return faPrint;
    case 'faSearch':
      return faSearch;
    case 'faShare':
      return faShare;
    case 'faShieldAlt':
      return faShieldAlt;
    case 'faShieldCheck':
      return faShieldCheck;
    case 'faShoppingCart':
      return faShoppingCart;
    case 'faTimes':
      return faTimes;
    case 'faThumbsUp':
      return faThumbsUp;
    case 'faTools':
      return faTools;
    case 'faUniversity':
      return faUniversity;
    case 'faUser':
      return faUser;
    case 'faUserPlus':
      return faUserPlus;
    case 'faUsers':
      return faUsers;
    case 'faWindowAlt':
      return faWindowAlt;
    default:
      throw new Error(`No icon exists with the name: ${name}.`);
  }
};
