import { AnyAction } from 'redux';
import { Notification } from 'services';

import { StoreState } from './namecheck.interface';

const initialState: StoreState = {
  visibility: true,
  enteredName: '',
  submittedName: '',
  notification: null,
  isInProgress: false,
};

const UPDATE_NAMECHECKENTERED = 'UPDATE_NAMECHECKENTERED';

export const updateNameCheckEntered = (enteredName: string): any => ({
  type: UPDATE_NAMECHECKENTERED,
  enteredName,
});

const UPDATE_NAMECHECKPROGRESS = 'UPDATE_NAMECHECKPROGRESS';

export const updateNameCheckProgress = (progress: boolean): any => ({
  type: UPDATE_NAMECHECKPROGRESS,
  progress,
});

const UPDATE_NAMECHECKVISIBLE = 'UPDATE_NAMECHECKVISIBLE';

export const updateNameCheckVisible = (visible: boolean): any => ({
  type: UPDATE_NAMECHECKVISIBLE,
  visible,
});

const CLEAR_ALL = 'CLEAR_ALL';

export const clearAll = (): any => ({
  type: CLEAR_ALL,
  ...initialState,
});

const UPDATE_NAMECHECKNOTIFICATION = 'UPDATE_NAMECHECKNOTIFICATION';

export const updateNameCheckNotification = (notification: Notification): any => ({
  type: UPDATE_NAMECHECKNOTIFICATION,
  notification,
});

const namecheckReducer = (state = initialState, action: AnyAction): StoreState => {
  switch (action.type) {
    case UPDATE_NAMECHECKVISIBLE:
      return { ...state, visibility: action.payload };
    case UPDATE_NAMECHECKPROGRESS:
      return { ...state, isInProgress: action.payload };
    case UPDATE_NAMECHECKNOTIFICATION:
      return { ...state, notification: action.payload };
    case UPDATE_NAMECHECKENTERED:
      return { ...state, enteredName: action.payload };

    case CLEAR_ALL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default namecheckReducer;
