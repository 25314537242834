import React from 'react';

import { Link } from 'components/common/link/Link';
import { Props } from './ListItem.interface';

import { item, item__link } from './ListItem.scss';

export const ListItem = ({ to, children }: Props): JSX.Element => (
  <li className={item}>
    <Link to={to} className={item__link}>
      {children}
    </Link>
  </li>
);
