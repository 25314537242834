import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneSquareAlt';
import { faSquareEnvelope } from '@fortawesome/pro-solid-svg-icons/faSquareEnvelope';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import slugify from 'react-slugify';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

import { Link } from 'components/common/link/Link';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import BurgerMenuIcon from 'components/directus/burger-menu-icon/BurgerMenuIcon';
import headerHook from 'hooks/header/header.hook';
import navigationHook from 'hooks/navigation/navigation.hook';
import settingsHook from 'hooks/settings/settings.hook';
import { isAuthenticated } from 'services';
import { url } from 'utils/url';
import { StoreProps } from 'store/reducers/namecheck/namecheck.interface';

import { Props, NavColumn, NavItem } from './Navigation.interface';
import { DropDownList } from './list/DropDownList';
import { ListItemLink } from './list-item-link/ListItemLink';

import {
  header__navlist___home,
  header__navlist,
  header__navtab___home,
  header__navtab,
  contact__home,
  burger__button___home,
  header__overlay,
  header___home,
  header__bottom__wrap___home,
  header,
  wrapper,
  header__top,
  logo,
  logo__link,
  logo__link__image,
  contact,
  contact__container,
  contact__description,
  contact__number,
  contact__zendesk,
  burger,
  burger__button,
  header__bottom,
  header__bottom__wrap,
  siteNav,
  siteNav___open,
  siteNav__list,
  siteNav__list__item,
  userNav,
  userNav__list,
  userNav__list__icon,
  userNav__list__item,
} from './Navigation.scss';

const Navigation = ({ isHome, dispatch }: Props): JSX.Element => {
  const { node } = headerHook()[0];
  const navigationItems = navigationHook();
  const settings = settingsHook();
  const [isMenuOpen, updateIsMenuOpen] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const cookie = typeof window !== 'undefined' && window.document ? document.cookie : '';

  const source = useMemo(
    () => ({
      navListToggle: isHome ? `${header__navlist___home}` : `${header__navlist}`,
      navTabToggle: isHome ? `${header__navtab___home}` : `${header__navtab}`,
      contactInfoToggle: isHome ? `${contact__home}` : '',
      logoToggle: isHome ? node.logo_home : node.logo,
      loginToggle: isHome ? `${burger__button___home}` : '',
      headerToggle: isMenuOpen && isHome ? `${header__overlay}` : '',
      boxShadowToggle: isHome ? `${header___home}` : '',
      bottomWrap: isHome ? `${header__bottom__wrap___home}` : '',
      burgerColor: isHome ? '#ffffff' : '#000000',
    }),
    [isHome, isMenuOpen]
  );

  const left: any[] = [];
  const right: any[] = [];

  const clearNamecheck = (): void => {
    dispatch({ type: 'CLEAR_ALL' });
    removeCookie('company-name');
  };

  navigationItems.map(({ node }): void => {
    if (node.placement === 'Left') left.push(node);
    if (node.placement === 'Right') right.push(node);
    return null;
  });

  return (
    <header className={`${header} ${source.headerToggle} ${source.boxShadowToggle}`}>
      <Wrapper className={wrapper}>
        <div className={header__top}>
          <div className={logo}>
            <Link
              to="/"
              className={logo__link}
              onClick={(): void => {
                clearNamecheck();
              }}
            >
              <img
                src={source.logoToggle.data.full_url}
                alt={node.logo_alt}
                className={logo__link__image}
                loading="eager"
                style={{ maxWidth: '20rem' }}
                width="240px"
                height="25px"
              />
            </Link>
          </div>

          <div className={contact}>
            <div className={contact__container}>
              <a
                href={settings.zendesk_link}
                target="_blank"
                rel="noreferrer"
                className={`${contact__zendesk} ${source.contactInfoToggle}`}
              >
                <FontAwesomeIcon icon={faSquareEnvelope} />
                &nbsp;Email Us
              </a>
              <a
                href={`tel:+44${node.contact_number.substring(1)}`}
                className={`${contact__number} ${source.contactInfoToggle}`}
              >
                <FontAwesomeIcon icon={faPhoneSquareAlt} />
                &nbsp;{node.contact_number}
              </a>
            </div>
          </div>

          <div className={burger}>
            <button
              type="button"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
              aria-controls="navbar-toggle-external-content"
              onClick={() => updateIsMenuOpen(!isMenuOpen)}
              className={`${burger__button} ${source.loginToggle}`}
            >
              <BurgerMenuIcon open={isMenuOpen} color={source.burgerColor} />
            </button>
          </div>
        </div>

        <div className={header__bottom} id="navbar-toggle-external-content">
          <div className={`${header__bottom__wrap} ${source.bottomWrap}`}>
            <nav className={`${siteNav} ${isMenuOpen ? siteNav___open : ''}`}>
              <ul className={siteNav__list}>
                {left.map(({ text, link, columns }) => {
                  if (columns.length > 0) {
                    return (
                      <li key={`nav-left-column-${slugify(text)}`} className={source.navListToggle}>
                        <DropDownList isHome={isHome} title={text} dropDownCaret columns={columns.length}>
                          <div>
                            {columns.map(({ slug, items }: NavColumn) => (
                              <div key={`nav-left-${slugify(text)}-column-${slug}`}>
                                {items.map(({ text, link, divider, style_toggle }: NavItem) => {
                                  if (!divider) {
                                    return (
                                      <ListItemLink
                                        key={`column-${slug}-${slugify(text)}`}
                                        onClick={() => updateIsMenuOpen(false)}
                                        to={link}
                                        altStyle={style_toggle || false}
                                      >
                                        {text}
                                      </ListItemLink>
                                    );
                                  }

                                  return <li key={`column-${slug}-${slugify(text)}`} />;
                                })}
                              </div>
                            ))}
                          </div>
                        </DropDownList>
                      </li>
                    );
                  }
                  if (link === '/') {
                    return (
                      <li key={`nav-left-column-${slugify(text)}`} className={siteNav__list__item}>
                        <Link
                          to={link}
                          className={`${siteNav__list__item} ${source.navTabToggle}`}
                          onClick={(): void => {
                            updateIsMenuOpen(false);
                            clearNamecheck();
                          }}
                        >
                          {text}
                        </Link>
                      </li>
                    );
                  }
                  return (
                    <li key={`nav-left-column-${slugify(text)}`} className={siteNav__list__item}>
                      <Link
                        to={link}
                        className={`${siteNav__list__item} ${source.navTabToggle}`}
                        onClick={() => updateIsMenuOpen(false)}
                      >
                        {text}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>

            <nav className={`${userNav} ${isMenuOpen && siteNav___open}`}>
              <ul className={userNav__list}>
                {right.map(({ text, link, columns }) => {
                  if (columns.length > 0) {
                    return (
                      <li key={`nav-right-column-${slugify(text)}`} className={source.navListToggle}>
                        <DropDownList isHome={isHome} title={text} dropDownCaret columns={columns.length}>
                          <div>
                            {columns.map(({ slug, items }: NavColumn) => (
                              <div key={`nav-right-${slugify(text)}-column-${slug}`}>
                                {items.map(({ text, link, divider }: NavItem) => {
                                  if (!divider) {
                                    return (
                                      <ListItemLink
                                        key={`column-${slug}-${slugify(text)}`}
                                        to={link}
                                        onClick={() => updateIsMenuOpen(false)}
                                      >
                                        {text}
                                      </ListItemLink>
                                    );
                                  }

                                  return <li key={`column-${slug}-${slugify(text)}`} />;
                                })}
                              </div>
                            ))}
                          </div>
                        </DropDownList>
                      </li>
                    );
                  }

                  return (
                    <li key={`nav-right-column-${slugify(text)}`} className={source.navListToggle}>
                      <Link
                        to={link}
                        className={`${userNav__list__item} ${source.navTabToggle}`}
                        onClick={() => updateIsMenuOpen(false)}
                      >
                        {text}
                      </Link>
                    </li>
                  );
                })}
                <>
                  {isAuthenticated(cookie) ? (
                    <li key="nav-right-column-account" className={source.navListToggle}>
                      <DropDownList isHome={isHome} title="My Account" dropDownCaret columns={1}>
                        <ListItemLink to={url.account('/account/')}>Dashboard</ListItemLink>
                        <ListItemLink to={url.account('/payments/')}>Finances</ListItemLink>
                        <ListItemLink to={url.account('/services/')}>Services</ListItemLink>
                        <ListItemLink to={url.account('/companies/')}>Companies</ListItemLink>
                        <ListItemLink to={url.account('/order-history/')}>Orders</ListItemLink>
                        <ListItemLink to={url.account('/processed-mail/')}>Mail</ListItemLink>
                        <ListItemLink to={url.account('/logout')}>Log Out</ListItemLink>
                      </DropDownList>
                    </li>
                  ) : (
                    <li key="nav-right-column-login" className={source.navListToggle}>
                      <Link to={url.account('/login')} className={`${userNav__list__item} ${source.navTabToggle}`}>
                        <FontAwesomeIcon className={userNav__list__icon} icon={faLock} />
                        Login
                      </Link>
                    </li>
                  )}
                </>
              </ul>
            </nav>
          </div>
        </div>
      </Wrapper>
    </header>
  );
};

export default connect((state: StoreProps) => ({
  state: { ...state.nameCheck },
}))(Navigation);
