import React from 'react';

import { Link } from 'components/common/link/Link';
import { capitalize } from 'utils/capitalize';
import { externalCheck } from 'utils/external-check';
import { Props } from './Button.interface';

import {
  btnBlock,
  btn,
  btnText,
  btnPrimary,
  btnSecondary,
  btnSuccess,
  btnWarning,
  btnDanger,
  btnSmall,
  btnLarge,
} from './Button.scss';

const getBtnClass = (role: string) => {
  switch (capitalize(role)) {
    case 'Text':
      return btnText;
    case 'Primary':
      return btnPrimary;
    case 'Secondary':
      return btnSecondary;
    case 'Success':
      return btnSuccess;
    case 'Warning':
      return btnWarning;
    case 'Danger':
      return btnDanger;
    case 'Small':
      return btnSmall;
    case 'Large':
      return btnLarge;
    default:
      return '';
  }
};

export default ({
  to,
  ariaLabel,
  children,
  size,
  role,
  className,
  block,
  onClick,
  type = 'button',
  ...props
}: Props): JSX.Element => {
  const roleClass = role ? getBtnClass(role) : '';
  const sizeClass = size ? getBtnClass(size) : '';
  const blockClass = block ? btnBlock : '';

  if (to !== undefined) {
    if (externalCheck(to)) {
      return (
        <a
          className={`${btn} ${className || ''} ${blockClass} ${sizeClass} ${roleClass}`}
          href={to}
          target={to.indexOf('tel') === -1 ? '_blank' : ''}
          rel="nofollow noreferrer noopener"
          aria-label={ariaLabel}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        to={to}
        className={`${btn} ${className || ''} ${blockClass} ${sizeClass} ${roleClass}`}
        {...props}
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`${btn} ${className || ''} ${blockClass} ${sizeClass} ${roleClass}`}
      onClick={onClick}
      aria-label={ariaLabel}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  );
};
