const getNumberOfDays = (year: number, month: number) => {
  const d = new Date(year, month, 0);
  return d.getDate();
};

export const getSpecialOfferDate = () => {
  const today = new Date();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();
  const offerDate = date > 0 && date < 15 ? 15 : getNumberOfDays(year, month + 1);

  return `${offerDate} ${monthNames[month]} ${year}`;
};
