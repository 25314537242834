import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store';
import { WrapperProps } from './Wrapper.interface';

const ReduxWrapper = ({ element }: WrapperProps): JSX.Element => (
  <Provider store={configureStore()}>{element}</Provider>
);

export default ReduxWrapper;
