import React from 'react';
import { Helmet } from 'react-helmet';

import share from 'assets/images/share.png';
import favicon from 'assets/images/favicon.ico';

export default (): JSX.Element => {
  const themeColour = '#00b1e3';

  return (
    <Helmet titleTemplate="%s | Rapid Formations">
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=no" />
      <meta name="apple-mobile-web-app-capable" content="black" />
      <meta name="msapplication-navbutton-color" content={themeColour} />
      <meta name="msapplication-TileColor" content={themeColour} />
      <meta name="theme-color" content={themeColour} />
      <meta name="geo-placename" content="Rapid Formations, 71-75, Shelton Street, Covent Garden, London, WC2H 9J" />
      <meta name="geo-position" content="51.514705;-0.123609" />
      <meta name="geo-region" content="GB" />
      <meta name="og:image" content={share} />
      <meta name="og:image:width" content="880px" />
      <meta name="og:image:height" content="440px" />
      <meta name="og:locale" content="en_GB" />
      <meta name="og:type" content="website" />
      <meta name="og:url" content="/" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={share} />
      <meta name="twitter:site" content="@rapidukofficial" />
      <meta name="twitter:creator" content="@rapidukofficial" />
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
};
