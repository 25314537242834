import { useEffect, useState } from 'react';

export default (): {
  width: number;
  height: number;
} => {
  const getWindowDimensions = () => {
    if (typeof window !== 'undefined' || typeof document !== 'undefined') {
      return {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      };
    }
    return {
      width: 1920,
      height: 1080,
    };
  };
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setDimensions(getWindowDimensions()), 150);
    };
    window.addEventListener('resize', resizeListener, { passive: true });

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return dimensions;
};
