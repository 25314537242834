import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusHeader, HeaderEdge } from './header.interface';

export default (): HeaderEdge[] => {
  const { allDirectusHeader } = useStaticQuery<AllDirectusHeader>(graphql`
    query {
      allDirectusHeader {
        edges {
          node {
            directusId
            logo {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            logo_home {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            logo_alt
            contact_description
            contact_number
          }
        }
      }
    }
  `);

  return allDirectusHeader.edges;
};
