import React from 'react';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import accreditationsHook from 'hooks/accreditations/accreditations.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './Accreditation.interface';

export default ({ slug, className, width }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, accreditationsHook());

  return (
    <div className={className}>
      <Modal
        trigger={
          <Img
            fluid={node.logo.localFile.childImageSharp.fluid}
            alt={node.logo_alt}
            style={{
              width: `${width}px`,
            }}
          />
        }
        lazy
      >
        <Markdown source={node.body} container />
      </Modal>
    </div>
  );
};
