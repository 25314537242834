import React, { useEffect, useState } from 'react';
import { Script } from 'gatsby';
import { CookiesProvider, useCookies } from 'react-cookie';

import Helmet from 'components/common/helmet/Helmet';
import MainLayout from 'components/common/layouts/main-layout/MainLayout';
import HomeLayout from 'components/common/layouts/home-layout/HomeLayout';
import { PagePushContext } from 'providers/PagePushContext/PagePushContext';
import { cookieOptions } from 'utils/cookie';
import { Props } from './index.interface';

export default ({ children, pageContext }: Props): JSX.Element => {
  const [isPushed, setIsPushed] = useState<boolean>(false);
  const value = { isPushed, setIsPushed };
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (typeof window !== undefined) {
      const urlParams = new URLSearchParams(window.location.search);
      const clickId = urlParams.get('irclickid');
      setCookie('clickid', clickId, cookieOptions(60));
    }
  }, []);

  useEffect(() => {
    if (typeof window !== undefined && window.ire) {
      window.ire('identify', { customerId: '', customerEmail: '' });
    }
  }, []);

  const getLayout = () => {
    switch (pageContext.layout) {
      case 'home':
        return <HomeLayout>{children}</HomeLayout>;
      default:
        return <MainLayout>{children}</MainLayout>;
    }
  };

  return (
    <PagePushContext.Provider value={value}>
      <CookiesProvider>
        <Helmet />
        {getLayout()}
        <Script id="gatsby-plugin-impact" src="https://utt.impactcdn.com/A2757396-1b2f-49da-a3cb-72b411ce78251.js" />
      </CookiesProvider>
    </PagePushContext.Provider>
  );
};
