import React from 'react';

import { SocialIcon } from 'components/directus/social-icon/SocialIcon';
import { Props } from './SocialIcons.interface';

import { socialIcons, socialIcons__icon } from './SocialIcons.scss';

export default ({ slugs }: Props): JSX.Element => (
  <div className={socialIcons}>
    {slugs.map(
      (slug): JSX.Element => (
        <SocialIcon slug={slug} className={socialIcons__icon} key={`social-icon-${slug}`} />
      )
    )}
  </div>
);
