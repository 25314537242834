import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusSocialIcon, SocialIconEdge } from './social-icon.interface';

export default (): SocialIconEdge[] => {
  const { allDirectusSocialIcon } = useStaticQuery<AllDirectusSocialIcon>(graphql`
    query {
      allDirectusSocialIcon(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            slug
            icon_color
            url
          }
        }
      }
    }
  `);

  return allDirectusSocialIcon.edges;
};
