import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusReviewStat, ReviewStatEdges } from './review-stats.interface';

export default (): ReviewStatEdges[] => {
  const { allDirectusReviewStat } = useStaticQuery<AllDirectusReviewStat>(graphql`
    query {
      allDirectusReviewStat(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            slug
            provider
            score
            max_score
            total_reviews
            logo {
              data {
                full_url
              }
            }
            colour
            logo_width
            link
          }
        }
      }
    }
  `);

  return allDirectusReviewStat.edges;
};
