import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import Banner from 'components/common/banner/Banner';
import { Footer } from 'components/directus/footer/Footer';
import { PagePushContext } from 'providers/PagePushContext/PagePushContext';
import { Props } from '../Layouts.interface';

import {
  bannerPusher,
  bannerPushed,
  banner__actions,
  banner__close,
  mpPusher,
  mpPushed,
} from '../../banner/Banner.scss';
import { app as appClass } from '../Layouts.scss';

export default ({ children }: Props): JSX.Element => {
  const { isPushed, setIsPushed } = useContext(PagePushContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPushed(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={appClass} id="home-top">
        <Banner color="black" className={`${bannerPusher} ${isPushed ? bannerPushed : ''}`}>
          <div className={banner__actions}>
            <FontAwesomeIcon onClick={() => setIsPushed(false)} icon={faTimes} className={banner__close} />
          </div>
        </Banner>
        <div className={`${mpPusher} ${isPushed ? mpPushed : ''}`}>
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};
