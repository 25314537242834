import React from 'react';

import { Props } from './Wrapper.interface';

import { wrapper__container, wrapper__content } from './Wrapper.scss';

export const Wrapper = ({ children, className }: Props): JSX.Element => (
  <div>
    <div className={wrapper__container}>
      <div className={`${wrapper__content} ${className || ''}`}>{children}</div>
    </div>
  </div>
);
