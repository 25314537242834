import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

import { externalCheck } from 'utils/external-check';
import { Props } from './Link.interface';

export const Link = ({ children, to, newTab, ...props }: Props): JSX.Element => {
  if (externalCheck(to)) {
    return (
      <a href={to} rel="noopener noreferrer" {...props} target={newTab ? '_blank' : ''}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink to={to} {...props}>
      {children}
    </GatsbyLink>
  );
};
