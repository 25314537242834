import React from 'react';

import settingsHook from 'hooks/settings/settings.hook';

import {
  contacts,
  contacts__copyright,
  contacts__phone,
  contacts__link,
  contacts__email,
  contacts__reg,
} from './Contacts.scss';

export default (): JSX.Element => {
  const year = new Date().getFullYear();
  const settings = settingsHook();

  return (
    <div className={contacts}>
      <p className={contacts__copyright}>
        Copyright {year} &copy; Rapid Formations <span className={contacts__reg}>&reg;</span>
      </p>
      <div>
        <p className={contacts__phone}>
          Tel:&nbsp;
          <a href={`tel:+44${settings.telephone_number.substring(1)}`} className={contacts__link}>
            {settings.telephone_number}
          </a>
        </p>

        <p className={contacts__email}>
          <a href={settings.zendesk_link} className={contacts__link} target="_blank" rel="noreferrer">
            E-mail Us
          </a>
        </p>
      </div>
    </div>
  );
};
