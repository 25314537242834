import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons/faLayerGroup';

import { Link } from 'components/common/link/Link';
import { Props } from './ListItemLink.interface';

import { item, item__link, item__link__button, item__icon } from './ListItemLink.scss';

export const ListItemLink = ({ to, children, altStyle = false, onClick }: Props): JSX.Element => (
  <li className={item}>
    <Link to={to} className={item__link} onClick={onClick}>
      {altStyle ? (
        <span className={item__link__button}>
          <FontAwesomeIcon icon={faLayerGroup} className={item__icon} />
          {children}
        </span>
      ) : (
        <>
          <FontAwesomeIcon icon={faChevronRight} size="xs" className={item__icon} />
          {children}
        </>
      )}
    </Link>
  </li>
);
