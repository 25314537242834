/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/baseline.scss';
import ReduxWrapper from 'store/wrapper/ReduxWrapper';

export const wrapRootElement = ReduxWrapper;

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
  if (typeof wisepops !== 'undefined') {
    wisepops('pageview');
  }
};
