import React from 'react';

import cardsHook from 'hooks/cards/cards.hook';

import { cardIcons, cardIcons__image } from './CardIcons.scss';

export default (): JSX.Element => {
  const cards = cardsHook();

  return (
    <div className={cardIcons}>
      {cards.map(
        ({ node }): JSX.Element => (
          <img
            key={node.slug}
            src={node.logo.data.full_url}
            alt={node.logo_alt}
            title={node.logo_alt}
            className={cardIcons__image}
            width="65px"
            height="45px"
            loading="lazy"
          />
        )
      )}
    </div>
  );
};
